import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  slideUpVariants,
  slideLefVariants,
  slideRightVariants,
} from "../CustomHooks/animations/animation";
import { useNavigate } from "react-router-dom";

const images = [
  {
    src: "https://c4.wallpaperflare.com/wallpaper/303/255/774/equipment-medicine-laboratory-professionals-wallpaper-preview.jpg",
    text: (
      <motion.h1
        variants={slideRightVariants}
        className="text-white text-[30px] uppercase font-bold"
      >
        Evaluation of the Current Safety
      </motion.h1>
    ),
  },
  {
    src: "https://www.scilifelab.se/wp-content/uploads/2023/05/PM_Capability_BG6_approved-April-2023.png",
    text: (
      <motion.h1
        variants={slideRightVariants}
        className="text-white-500 text-[30px] uppercase font-bold"
      >
        Shriarc Pharmaceutical Story Priority Services Delivered with{" "}
      </motion.h1>
    ),
  },
  {
    src: "https://plus.unsplash.com/premium_photo-1672163163579-e5d4aedd26af?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8bWVkaWNpbmVzfGVufDB8fDB8fHww",
    text: (
      <motion.h1
        variants={slideRightVariants}
        className="text-white-500 text-[30px] uppercase font-bold"
      >
        Crafting a better future.
      </motion.h1>
    ),
  },
];

const Home = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <div
        id="home"
        className=" border bsolute w-full flex left-1 items-center lg:h-[700px]  h-fit bg-center bg-cover top-12px"
        style={{
          backgroundImage: `url(${images[activeIndex].src})`,
          transition: "background-image 1s ease-in-out",
          backgroundSize: "cover",
          height: "510px",
          backgroundPosition: "center",
          boxShadow: "0px 4px 1px rgba(0, 0, 0, 0.5)",
        }}
      >
        <div className="absolute top-0 h-[590px] inset-0 bg-black bg-opacity-50"></div>

        {/* Text Section */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={slideUpVariants}
          className="relative z-10 text-center lg:w-[60%] w-full flex flex-col justify-center items-center gap-1 border-red-400 text-white px-[20px]"
        >
          <motion.h1
            variants={slideLefVariants}
            className="text-white-700 text-4xl font-semibold"
          >
            SHRIARC PHARMA
          </motion.h1>
          {images[activeIndex].text}
          <div className="w-[120px] h-[6px] bg-orange-500"></div>
          <p className="text-white text-[20px]">
            SHRI<span className="text-orange-500 italic">ARC </span>{" "}
            Delivering Quality Pharmaceuticals at Affordable Prices
          </p>
        </motion.div>
      </div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={slideUpVariants}
        className="g:w-[80%] w-[90%] m-auto py-[60px] flex flex-col justify-center items-center   gap-4 "
      >
        <motion.h1
          variants={slideRightVariants}
          className="text-xl text-black-500 font-medium leading-relaxed"
        >
          At SHRIARC, we believe that everyone deserves access to high-quality
          healthcare products without the burden of high costs. As a dynamic
          pharmaceutical startup, our mission is simple: to provide top-tier
          medications and healthcare solutions that are both effective and
          affordable.
          <br />
          <br />
          With a commitment to excellence, innovation, and patient well-being,
          we are dedicated to making a positive impact on the healthcare
          industry. We leverage advanced technology, rigorous quality control,
          and a customer-centric approach to create pharmaceuticals that meet
          global standards while remaining accessible to all.
        </motion.h1>
        <div className="w-[120px] h-[6px] bg-orange-500"></div>

         <div className=" flex lg:flex-row  flex-col  lg:gap-[20px] gap-[1px]"> 
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={slideUpVariants}
            className="lg:w-[60]% w-full lg:h-[400px] h-[200px] flex flex-col justify-center items-start "
          >
            <motion.img
              initial="hidden"
              whileInView="visible"
              variants={slideUpVariants}
              src="https://th.bing.com/th/id/R.0e56201dc1e6788756c5c9f1bdfc5dd3?rik=4MryWt9gfnBT5w&riu=http%3a%2f%2fwww.pharmaadda.in%2fwp-content%2fuploads%2f2019%2f04%2fMNC-Pharma-companies-in-India.jpg&ehk=sdDcMEdt%2fmADlttezddV1h2ab4hd9miqXLu4WoCAc5Y%3d&risl=&pid=ImgRaw&r=0"
              alt="About Us"
              className=" lg:w-[60]% w-full h-auto rounded-lg shadow-lg"
            />
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={slideUpVariants}
            className="lg:w-[4 0]% w-full flex flex-col justify-center items-start gap-6"
          >
            <motion.h1
              initial="hidden"
              whileInView="visible"
              variants={slideUpVariants}
              className="text-xl font-bold"
            >
              Welcome To{" "}
              <span className="text-3xl text-orange-500 font-semibold">
                SHRIARC
              </span>{" "}
              Pharmaceuticals Private Limited. Leading Pharma Company in India.
            </motion.h1>
            <p className="text-black text-lg text-justify ">
              Founded in 2024, SHRIARC Pharmaceuticals is a new-age
              pharmaceutical company dedicated to revolutionizing the healthcare
              industry with a vision for excellence, innovation, and
              affordability. While we are new, our commitment to making a
              meaningful impact in the lives of people around the world is
              unwavering.
            </p>
          </motion.div>
    </div> 
      </motion.div>
      <div className="px-6 py-8 bg-gray-50">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
          Our Approach to Affordable Quality Pharmaceuticals
        </h2>
        <div className="space-y-6">
          {/* Quality You Can Trust */}
          <div className="bg-white shadow-lg p-6 rounded-lg flex items-start">
            <div className="text-blue-600 text-3xl mr-4">
              <i className="fas fa-shield-alt"></i>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-gray-800">
                Quality You Can Trust
              </h3>
              <p className="text-gray-600 mt-2">
                Our products are manufactured using the highest quality
                standards and undergo rigorous testing to ensure their safety,
                efficacy, and consistency. We adhere to Good Manufacturing
                Practices (GMP) and all necessary regulatory guidelines to
                deliver products you can trust.
              </p>
            </div>
          </div>

          {/* Affordable Prices */}
          <div className="bg-white shadow-lg p-6 rounded-lg flex items-start">
            <div className="text-green-600 text-3xl mr-4">
              <i className="fas fa-dollar-sign"></i>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-gray-800">
                Affordable Prices
              </h3>
              <p className="text-gray-600 mt-2">
                We are committed to keeping healthcare affordable. By
                streamlining our processes and optimizing production, we offer
                cost-effective solutions that make essential medicines available
                to a broader audience without compromising on quality.
              </p>
            </div>
          </div>

          {/* Innovative Product Development */}
          <div className="bg-white shadow-lg p-6 rounded-lg flex items-start">
            <div className="text-purple-600 text-3xl mr-4">
              <i className="fas fa-flask"></i>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-gray-800">
                Innovative Product Development
              </h3>
              <p className="text-gray-600 mt-2">
                Our experienced team works tirelessly to develop new
                pharmaceutical formulations and treatments that address
                real-world healthcare needs. We invest in research and
                development to bring innovative, high-quality products to market
                at affordable prices.
              </p>
            </div>
          </div>

          {/* Patient-Centric Solutions */}
          <div className="bg-white shadow-lg p-6 rounded-lg flex items-start">
            <div className="text-red-600 text-3xl mr-4">
              <i className="fas fa-user-md"></i>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-gray-800">
                Patient-Centric Solutions
              </h3>
              <p className="text-gray-600 mt-2">
                At SHRIARC, we place patients at the heart of everything we do.
                We work closely with healthcare providers to ensure that our
                products meet the unique needs of individuals, delivering
                reliable and effective treatments for a variety of conditions.
              </p>
            </div>
          </div>

          {/* Wide Range of Products */}
          <div className="bg-white shadow-lg p-6 rounded-lg flex items-start">
            <div className="text-orange-600 text-3xl mr-4">
              <i className="fas fa-pills"></i>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-gray-800">
                Wide Range of Products
              </h3>
              <p className="text-gray-600 mt-2">
                From over-the-counter medicines to specialized prescription
                treatments, we offer a comprehensive portfolio of products
                designed to support the health and wellness of individuals
                across all age groups.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full p-[20px] flex justify-between items-center bg-orange-500">
        <h3 className="text-xl font-bold text-white">
          Have An Order? Get In Touch With - Shriarc Pharmaceuticals.
        </h3>
        <button
          className="bg-orange-600 hover:bg-orange-700 border-2 border-white px-10 py-3 rounded-lg text-white font-bold transition duration-300"
          onClick={() => navigate("/contactUs")}
        >
          CONTACT US
        </button>
      </div>
    </>
  );
};

export default Home;
