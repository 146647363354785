import React from "react";
import { motion } from "framer-motion";
import {
  slideUpVariants,
  slideLefVariants,
} from "../CustomHooks/animations/animation";

const About = () => {
  return (
    <>
    <div
      id="about"
      className="lg:w-[80%] w-[90%] m-auto py-[60px] flex lg:flex-row  flex-col  justify-between items-start gap-[50px]"
    >
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={slideUpVariants}
        className="lg:w-[60]% w-full flex flex-col justify-center items-start "
      >
        <motion.h1
          variants={slideLefVariants}
          className="text-black-500 text-xl font-bold"
        >
          About US
        </motion.h1>
        <motion.h1
          variants={slideLefVariants}
          className="text-orange-500 uppercase text-[40px] font-bold "
        >
          SHRI<span className="text-orange-500 italic">ARC </span>
        </motion.h1>
        <div className="w-[100px] h-[6px] bg-black"></div>
        <p className="font-bold text-xl text-black mt-[60px]  ">
        Driven by Science, Powered by Care—SHRIARC is Redefining the Pharmaceutical Landscape.
        </p>
      </motion.div>

      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={slideUpVariants}
        className="lg:w-[4 0]% w-full flex flex-col justify-center items-start gap-6"
      >
        <p className="text-black text-lg text-justify ">
        Founded in 2024, SHRIARC is revolutionizing the pharmaceutical industry with a fresh, bold approach. Though we are a young company, we are quickly making our mark as a trailblazer in the field. Our unwavering commitment to quality, research, and technological innovation positions us ahead of the curve. At SHRIARC, we’re not just entering the industry—we’re changing the way the world thinks about healthcare. Our passion for transforming lives through groundbreaking pharmaceutical solutions is at the heart of everything we do.
        </p>
        
      </motion.div>

    </div>

    <div
      id="about"
      className="w-[90%] lg:w-[80%] m-auto py-[60px] flex flex-col justify-start items-center gap-[50px]"
    >
      {/* Why Choose Us Section */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={slideUpVariants}
        className="w-full text-center mt-12"
      >
        <motion.h1 className="text-black uppercase text-[40px] font-bold">
          WHY CHOOSE <span className="text-orange-500 italic">SHRIARC</span>
        </motion.h1>
        <div className="w-[120px] h-[6px] bg-orange-500 mx-auto mt-1"></div>
        <p className="font-bold text-xl text-black mt-[40px]">
        Quality You Can Trust, Innovation You Can Rely On—Welcome to SHRIARC Pharmaceuticals.
        </p>
      </motion.div>

      {/* Why Choose Us Points */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={slideUpVariants}
        className="w-full flex flex-col justify-center items-start gap-8 mt-12"
      >
        {/* Commitment to Quality */}
        <div className="flex flex-col items-start">
          <h3 className="text-orange-500 font-bold text-2xl mb-4">
            Commitment to Quality
          </h3>
          <p className="text-black text-lg text-justify">
            Our focus on quality control ensures that every product we offer
            meets the highest safety and efficacy standards. We are dedicated to
            delivering only the best to our customers.
          </p>
        </div>

        {/* Affordable Solutions */}
        <div className="flex flex-col items-start">
          <h3 className="text-orange-500 font-bold text-2xl mb-4">
            Affordable Solutions
          </h3>
          <p className="text-black text-lg text-justify">
            We believe in making healthcare accessible. Our business model is
            designed to minimize costs while maximizing the value we provide to
            our customers.
          </p>
        </div>

        {/* Sustainability and Responsibility */}
        <div className="flex flex-col items-start">
          <h3 className="text-orange-500 font-bold text-2xl mb-4">
            Sustainability and Responsibility
          </h3>
          <p className="text-black text-lg text-justify">
            We strive to make a positive impact not only in healthcare but also
            in the communities we serve. Our operations are guided by a
            commitment to environmental sustainability and corporate
            responsibility.
          </p>
        </div>

        {/* Transparency and Trust */}
        <div className="flex flex-col items-start">
          <h3 className="text-orange-500 font-bold text-2xl mb-4">
            Transparency and Trust
          </h3>
          <p className="text-black text-lg text-justify">
            We believe in building long-term relationships with our customers.
            By maintaining transparency in our processes, pricing, and
            sourcing, we ensure that our clients can trust us to provide the
            best pharmaceutical products.
          </p>
        </div>
      </motion.div>

      {/* Our Promise Section */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={slideUpVariants}
        className="w-full text-center mt-12"
      >
        <h2 className="text-black font-bold text-3xl mb-6">Our Promise</h2>
        <p className="text-black text-lg text-justify">
          At SHRIARC, we are more than just a pharmaceutical company – we are a
          partner in health. Our goal is to help you live a healthier, happier
          life by providing high-quality, affordable pharmaceutical solutions
          that you can rely on.
        </p>
        <p className="text-black text-lg text-justify mt-4">
          Join us in our mission to make healthcare accessible for everyone.
          Contact us today to learn more about our products and how we’re
          working to shape the future of affordable healthcare.
        </p>
      </motion.div>
    </div>


    </>
  );
};

export default About;
