import React from "react";
import logo from "../../../src/Components/asserts/logohead.svg";

const Footer = () => {
  return (
    <footer className="w-full bg-black text-white py-8 px-4">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
        {/* Logo and Description */}
        <div className="text-center md:text-left">
          <div className="flex justify-center md:justify-start items-center gap-2 mb-4">
            <img
              src={logo}
              alt="logo"
              width="30"
              height="30"
              className="cursor-pointer"
            />
            <h1 className="text-xl font-bold font-rubik text-orange-500">
              SHRIARC PHARMA
            </h1>
          </div>
          <p className="text-sm">
            Shriarc Pharmaceuticals Pvt. Ltd is a marketing-based
            pharmaceutical company with its main focus on making available
            quality pharmaceutical products at affordable costs to all.
          </p>
        </div>

        {/* Quick Links */}
        <div className="text-center md:text-left">
          <h2 className="text-lg font-bold mb-4">Quick Links</h2>
          <ul className="space-y-2">
            <li>
              <a href="/aboutUS" className="footer-link">
                About Us
              </a>
            </li>
            <li>
              <a href="/privacy" className="footer-link">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="/terms" className="footer-link">
                Terms & Conditions
              </a>
            </li>
          </ul>
        </div>

        {/* Social Links */}
        <div className="text-center md:text-left">
          <h2 className="text-lg font-bold mb-4">Follow Us</h2>
          <ul className="space-y-2">
            <li>
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                Facebook
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                Twitter
              </a>
            </li>
            <li>
              <a
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                LinkedIn
              </a>
            </li>
          </ul>
        </div>

        {/* Contact Us */}
        <div className="text-center md:text-left">
          <h2 className="text-lg font-bold mb-4">Contact Us</h2>
          <ul className="space-y-2">
            <li className="text-sm">
              <strong>Address:</strong> Plot no 911, Road no 28, Tirumala nagar, Hyderabad, Telangana - 500112, India.
            </li>
            <li className="text-sm">
              <strong>Phone:</strong>{" "}
              <a href="tel:+91 78423 89502" className="footer-link">
              +91 78423 89502
              </a>
            </li>
            <li className="text-sm">
              <strong>Email:</strong>{" "}
              <a
                href="mailto:info@shriarcpharma.com"
                className="footer-link"
              >
                info@shriarc.com
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="border-t border-gray-600 mt-8 pt-4 text-center">
        <p className="text-sm">&copy; 2024 SHRIARC. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
