import React from "react";
import "./App.css";
import Home from "./Components/pages/Home";
import About from "./Components/pages/About";
import Products from "./Components/pages/Products";
import Contact from "./Components/pages/Contact";
import ResponsiveAppBar from "./Components/CustomHooks/AppBar";
import Footer from "./Components/CustomHooks/Footer";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        {/* Navigation Bar */}
        <ResponsiveAppBar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutUS" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route path="/contactUs" element={<Contact />} />
          <Route path="/groupOfCompanies" element={<About />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>

        {/* Footer */}
        <Footer />
      </Router>
    </div>
  );
}

export default App;
